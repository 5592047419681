<template>
  <div>
    <Hero :visible.sync="visible" :show-cutout="false" class="pb-56 pt-28">
      <b-container class="mt-20">
        <b-row>
          <b-col lg="10" offset-lg="1" class="text-center">
            <h1
              class="
                text-4xl text-md-6xl text-lg-8xl text-white
                font-weight-black font-realist
                tracking-tight
                leading-none
                mb-md-8 mb-4
              "
            >
              Terms and Conditions
            </h1>
          </b-col>
        </b-row>
      </b-container>
    </Hero>
    <main>
      <b-container class="mt-n32 mb-24">
        <b-row>
          <b-col md="10" offset-md="1" lg="8" offset-lg="2">
            <div class="bg-white rounded shadow p-12">
              <p class="text-center">
                <strong>
                  True360 Services<br />Terms and Conditions<br />Effective June
                  10, 2022</strong
                >
              </p>
              <p>
                This is a binding legal agreement between ACV Auctions Inc.,
                acting for itself and its affiliates (“ACV” or “us” or “we”),
                and the customer (“Customer” or “you”) receiving ACV’s True360
                standard inspection services on vehicles with an accident
                history report (“True360 Services”). You and we are sometimes
                referred to individually as a “party” and collectively as the
                “parties.” These True360 Terms and Conditions (“Terms”) are
                effective as of the effective date specified above, as updated
                from time to time.
              </p>

              <h4>1.&nbsp; Services</h4>
              <p>
                For each vehicle you request, ACV will provide its True360
                Services and promptly thereafter will deliver to you by email an
                electronic copy of the final inspection report (“Report”). You
                agree to make the vehicles available for inspection, and ACV
                agrees to use reasonable efforts to inspect the vehicles, in
                accordance with the schedule mutually agreed by the parties from
                time to time. If either party will be unavailable on a scheduled
                inspection day or at a scheduled inspection time, that party
                will notify the other party as promptly as possible prior
                thereto so the parties can make alternative arrangements for
                that week.
              </p>

              <h4>2. Fees; Order Forms.</h4>
              <p>
                The fees for the True360 Services will be set forth on one more
                order form(s) between ACV and Customer (“Order Form”), each of
                which is governed by these Terms. Unless otherwise specified in
                the Order Form, ACV will issue invoices for the True360 Services
                promptly after completion of services. Invoices will be sent to
                the billing email address specified in the Order Form, and shall
                be due and payable no later than thirty (30) days after the date
                of invoice.
              </p>
              <h4>3. Participating Dealers.</h4>
              <p>
                If you desire to make the True360 Services available to any
                dealership within your network (“Participating Dealers”), you
                may only do so if you identify the Participating Dealer to ACV
                in the applicable Order Form. The parties may update the
                Participating Dealer list from time to time only by mutual
                written agreement (with email confirmation between the parties
                being sufficient for such purposes). You agree to provide ACV
                all information and materials reasonably requested by ACV to
                enable access to the True360 Services to Participating Dealers.
                You shall ensure that all Participating Dealers are in
                compliance with your obligations under these Terms and shall be
                responsible and liable for the acts or omissions of
                Participating Dealers.
              </p>

              <h4>4. Widget Authorization.</h4>
              <p>
                You hereby agree to include the True360 vehicle display page
                widget and search results page widget on the vehicle web page(s)
                of your web site(s) and the web site(s) of all Participating
                Dealers, which will include an embedded link where users can
                view the Report for the applicable vehicle that ACV has
                inspected. In connection therewith you hereby authorize ACV (i)
                to display the widgets on all such websites, (ii) to contact the
                web site provider(s) set forth in the Order Form to assist with
                such implementation, and (iii) to contact you directly to assist
                and answer questions about the widgets.
              </p>

              <h4>5. Use of Reports.</h4>
              <p>
                Reports are for your internal use only in connection with the
                sale of the applicable vehicle. Reports may not be distributed,
                reproduced, or sold to any third party and may not be displayed,
                except in accordance with the Widget Authorization as described
                below. Reports may not be altered or modified and must include
                all ACV and True360 notices as they appear on the Report.
              </p>

              <h4>6. Data Access.</h4>
              <p>
                You agree you will use reasonable efforts to make available to
                ACV through an automated or other mutually agreeable method
                vehicle profile, sales and related performance data which ACV
                may use for your benefit in connection with the True360 Services
                and may aggregate with other ACV data, in which case, ACV may
                use such aggregated data for its internal and commercial
                business purposes.
              </p>

              <h4>7. Warranties; Disclaimers; Limitations.</h4>
              <p>
                You represent and warrant to ACV that you and/or the
                Participating Dealer, as the case may be, are the true and
                lawful owner of the vehicles you make available for inspection
                and/or have all rights necessary to allow ACV to perform the
                True360 Services on such vehicles. ACV warrants it will provide
                the True360 Services in a professional manner, using reasonable
                care and diligence. Except as stated in the preceding sentence,
                ACV PROVIDES THE TRUE360 SERVICES ON AN “AS IS” AND “AS
                AVAILABLE” BASIS, AND HEREBY DISCLAIMS ALL EXPRESS AND IMPLIED
                WARRANTIES, INCLUDING ANY IMPLIED WARRANTIES OF MERCHANTABILITY,
                FITNESS FOR A ‬‬PARTICULAR PURPOSE, OR ANY WARRANTIES WHICH MAY
                BE CREATED THROUGH COURSE OF ‬‬DEALING. WITH RESPECT TO EACH
                REPORT DELIVERED HEREUNDER, CUSTOMER UNDERSTANDS THE REPORT WILL
                NOT INCLUDE AN EVALUATION OF THE VEHICLE’S UNDERBODY STRUCTURAL,
                MECHANICAL, OR INTERIOR CONDITION AND ACV IN NO WAY GUARANTEES
                THE SAFETY OF THE VEHICLE, OR THAT ALL PRIOR REPAIRS OR EXISTING
                DAMAGE WILL BE IDENTIFIED. ORDINARY WEAR AND TEAR AND OTHER
                EXTERNAL FACTORS CAN ALTER A VEHICLE’S CONDITION; THE REPORT
                SUMMARIZES THE CONDITION OF THE VEHICLE OBSERVED BY THE
                INSPECTOR ONLY AT THE TIME OF INSPECTION. YOU AGREE THAT ACV’S
                SOLE LIABILITY UNDER THESE TERMS FOR ANY DAMAGES WHATSOEVER,
                WHETHER ACTUAL, DIRECT, INDIRECT, OR OTHERWISE, IS LIMITED TO
                AMOUNTS PAID FOR THE INPECTION REPORT FROM WHICH THE LIABILITY
                AROSE.
              </p>

              <h4>8. Term.</h4>
              <p>
                These Terms will be in effect for so long as there are any
                outstanding Order Forms. ACV reserves the right to suspend the
                True360 Services at any time at ACV’s discretion and without
                notice if, for example, you have not timely paid amounts due to
                ACV or otherwise have breached these Terms.
              </p>

              <h4>9. General.</h4>
              <p>
                These Terms shall not confer any rights or remedies upon any
                person other than ACV and Customer and their respective
                successors and permitted assigns. The True360 Services are
                provided by ACV as an independent contractor. Nothing in these
                Terms shall be deemed to create any partnership or joint
                venture. ACV may provide some or all of the True360 Services
                through third party subcontractors of its choice; provided it
                shall be responsible for the acts or omissions of such
                subcontractors in providing the True360 Services. The section
                headings contained in these Terms are inserted for convenience
                only and shall not affect in any way the meaning or
                interpretation of these Terms. No waiver by either party of any
                breach of, or of compliance with, any condition or provision of
                these Terms by the other party shall be considered a waiver of
                any other condition or provision or of the same condition or
                provision at another time. ACV may set off any amount owed by
                Customer from any funds due from ACV. These Terms (together with
                any Order Forms) constitute the entire agreement between ACV and
                the Customer and supersedes any prior understandings,
                agreements, or representations by or between the parties,
                written or oral, to the extent they relate in any way to the
                subject matter hereof. Any and all claims arising out of,
                relating to or in connection with these Terms, shall in all
                respects and to the maximum extent permitted by applicable law
                be governed by the laws of the State of New York. Each party
                consents to the exclusive jurisdiction of the state and federal
                courts located in Erie County, New York.
              </p>

              <h4>10. Changes.</h4>
              <p>
                ACV reserves the right, in its sole discretion, to modify or
                replace these Terms at any time. Modifications will take effect
                immediately upon posting of the revised Terms. ACV recommends
                that Customer review the latest version of these Terms from time
                to time.
              </p>

              <h4>11. Notices</h4>
              <p>
                All notices, requests, demands, claims, and other communications
                hereunder shall be in writing. Any notice, request, demand,
                claim, or other communication hereunder shall be deemed duly
                given (a) when delivered personally to the recipient, (b) one
                business day after being sent to the recipient by reputable
                overnight courier service (charges prepaid), (c) upon electronic
                confirmation of receipt when transmitted by facsimile
                transmission or by electronic mail, or (d) four business days
                after being mailed to the recipient by certified or registered
                mail, return receipt requested and postage prepaid. Notices to
                ACV should be addressed to ACV Auctions Inc., 640 Ellicott
                Street, Suite 321, Buffalo, NY 14203; Attn: Legal Department;
                with a copy to: <strong>notices@acvauctions.com</strong>.
                Notices to Customer should be addressed as provided in the
                applicable Order Form.
              </p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </main>
  </div>
</template>

<script>
/* eslint-disable */
import Hero from "@/components/global/Hero";

export default {
  name: "Terms",
  components: {
    Hero,
  },
  data() {
    return {
      visible: true,
    };
  },
};
</script>